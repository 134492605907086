<template>
  <vx-card
    title="تعديل طلبية"
    title-color="primary"
    v-if="Object.keys(order).length"
  >
    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
        <label class="vs-input--label">للمستخدم</label>
        <vs-input v-model="order.user.name" class="w-full" disabled></vs-input>
        <span
          class="text-danger text-sm"
          v-show="errors.has('order-items.to-the-user')"
          >{{ errors.first("order-items.to-the-user") }}
        </span>
      </div>
      <div class="vx-col md:w-1/2 w-full">
        <label class="vs-input--label"> حالة الطلب</label>
        <v-select
          v-model="order.order_status_id"
          name="order_status"
          v-validate="'required'"
          data-vv-as="حالة الطلب"
          label="label"
          :reduce="(item) => item.value"
          :options="orderStatuses"
          :disabled="isEditable"
        ></v-select>
        <span class="text-danger text-sm" v-show="errors.has('order_status')"
          >{{ errors.first("order_status") }}
        </span>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
        <label class="vs-input--label mt-5">وسيلة الدفع</label>
        <v-select
          v-model="this.order.invoice.payment_method_id"
          name="payment_method"
          v-validate="'required'"
          data-vv-as="وسيلة الدفع"
          label="label"
          :disabled="order.order_status_id != 1"
          :reduce="(item) => item.value"
          :options="paymentMethods"
        ></v-select>
        <span class="text-danger text-sm" v-show="errors.has('payment_method')"
          >{{ errors.first("payment_method") }}
        </span>
      </div>
      <div class="vx-col md:w-1/2 w-full">
        <label class="vs-input--label">استخدام كوبون</label>
        <v-select
          v-model="order.invoice.coupon"
          name="coupon"
          label="code"
          :disabled="isEditable"
          :options="coupons"
        ></v-select>
      </div>
    </div>
    <vs-divider position="left" textClass="text-size" color="primary"
      >التوصيل</vs-divider
    >
    <div class="vx-row">
      <div class="vx-col md:w-2/5 w-full">
        <label class="vs-input--label">مندوب التوصيل</label>
        <v-select
          v-model="order.delivery_id"
          name="delivery"
          label="name"
          :reduce="(item) => item.id"
          :disabled="isEditable"
          :options="deliveries"
        ></v-select>
      </div>
      <div class="vx-col md:w-2/5 w-full">
        <label class="vs-input--label">تاريخ التوصيل</label>
        <datepicker
          class="w-full"
          format="dd/MM/yyyy"
          data-vv-as="تاريخ التوصيل"
          name="delivery_date"
          :disabled="isEditable"
          v-model="order.delivery_date"
          tabindex="2"
        ></datepicker>
        <span class="text-danger text-sm" v-show="errors.has('delivery_date')"
          >{{ errors.first("delivery_date") }}
        </span>
      </div>
      <div class="vx-col md:w-1/5 w-full">
        <label class="vs-input--label mt-5">سعر التوصيل</label>
        <vs-input
          data-vv-as="سعر التوصيل"
          v-validate="'numeric|min:0'"
          type="number"
          class="w-full"
          name="delivery_price"
          :disabled="isEditable"
          v-model="order.delivery_price"
        />
        <span class="text-danger text-sm" v-show="errors.has('delivery_price')"
          >{{ errors.first("delivery_price") }}
        </span>
      </div>
    </div>
    <vs-divider position="left" textClass="text-size" color="primary"
      >اضافة المنتجات</vs-divider
    >
    <div class="vx-row" v-if="order.items.length">
      <OrderItems
        :order_item_list="order.items"
        @deleteItem="deleteItem"
        @showItem="showItem"
      ></OrderItems>
      <vs-table hoverFlat class="w-1/2 ml-auto mt-4" data="invoiceData">
        <vs-tr>
          <vs-th>المبلغ الإجمالي</vs-th>
          <vs-td>{{ order.total_amount }} د.ل</vs-td>
        </vs-tr>

        <vs-tr>
          <vs-th>تفاصيل الاجمالي</vs-th>
          <vs-td>
            (
            <span
              style="width: fit-content"
              class="mx-1"
              v-for="(value, currency) in order.invoice.rest_amount_details"
              :key="currency"
            >
              {{ value + " " + (currency == "LYD" ? "د.ل" : currency) }}
              , </span
            >)
          </vs-td>
        </vs-tr>

        <vs-tr>
          <vs-th>التخفيض</vs-th>
          <vs-td>{{ order.invoice.discount }} د.ل</vs-td>
        </vs-tr>

        <vs-tr v-if="order.invoice.coupon">
          <vs-th> الكوبون المستخدم</vs-th>
          <td>
            {{ order.invoice.coupon.code }} |
            {{ order.invoice.coupon.value }} د.ل
          </td>
        </vs-tr>
        <vs-tr>
          <vs-th>سعر التوصيل</vs-th>
          <td>{{ order.delivery_price }} د.ل</td>
        </vs-tr>
        <vs-tr>
          <vs-th>وسيلة الدفع</vs-th>
          <td>
            {{ order.invoice.payment_method.name }}
          </td>
        </vs-tr>
      </vs-table>
    </div>

    <div class="vx-row align-items-center" v-if="!isEditable">
      <div class="vx-col w-1/5 sm:w-1/5 w-full">
        <label class="vs-input--label"> اختر رقم sku</label>
        <v-select
          name="sku"
          class="w-full"
          label="sku"
          v-model="product"
          @option:selected="clearFields"
          :options="products"
          :clearable="false"
          @input="getProductData"
          :filterable="true"
        ></v-select>
      </div>
      <div class="vx-col w-1/5 sm:w-1/5 w-full">
        <label class="vs-input--label">اختر اللون</label>
        <v-select
          class="w-full"
          name="color"
          label="name"
          v-model="color_option"
          :options="quantities"
          :clearable="false"
          :tabindex="4"
          :filterable="true"
        ></v-select>
      </div>
      <div class="vx-col w-1/5 sm:w-1/5 w-full">
        <label class="vs-input--label">اختر الحجم</label>
        <v-select
          class="w-full"
          name="size"
          label="size"
          v-model="size_option"
          :options="color_option.quantities"
          :clearable="false"
          :tabindex="4"
          :filterable="true"
        ></v-select>
      </div>
      <div class="vx-col w-1/5 sm:w-1/5 w-full">
        <label class="vs-input--label">اختر الكمية</label>
        <vs-input
          data-vv-as="الكمية"
          v-validate="{
            max_value: size_option.quantity,
            required: this.product ? false : true,
            min_value: 1,
          }"
          type="number"
          class="w-full"
          name="quantity"
          v-model="quantity"
        />
        <span class="text-danger text-sm" v-show="errors.has('quantity')"
          >{{ errors.first("quantity") }}
        </span>
      </div>
      <div class="vx-col w-1/5 sm:w-1/5 w-full flex">
        <vs-button
          class="mt-5"
          color="success"
          type="filled"
          icon-pack="feather"
          icon="icon-plus"
          :disabled="!validateForm"
          @click="addProduct"
        ></vs-button>
        <vs-button
          class="mt-5 ml-2"
          color="warning"
          type="filled"
          icon-pack="feather"
          icon="icon-rotate-ccw"
          @click="resetInputs"
        ></vs-button>
      </div>
    </div>
    <div class="vx-row mt-8">
      <div class="vx-col sm:w-full w-full mt-10 text-right">
        <vs-button
          class="mr-5 text-lg py-4"
          @click="$router.push({ name: 'orders',query: { page: $route.query.page, tab: $route.query.tab },})"
          color="warning"
          type="border"
          >إلغاء</vs-button
        ><vs-button
          class="mr-5 text-lg px-16 py-4"
          @click="submit"
          color="primary"
          type="filled"
          :disabled="loading"
          >تعديل طلب</vs-button
        >
      </div>
    </div>
  </vx-card>
</template>

<script>
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import OrderItems from "./components/order-items.vue";
import { mapActions } from "vuex";
export default {
  components: { vSelect, Datepicker, OrderItems },
  data() {
    return {
      order: {},
      isEditable: true,
      payment_method_id: "",
      product: {
        id: "",
        sku: "",
        title: "",
        price: "",
        currency: {},
      },
      quantity: "",
      color_option: {},
      size_option: { id: "", quantity: '', size: "" },
      quantities: [],
      deletedItems: [],
      local_currency: {},
    };
  },
  computed: {
    loading() {
      return this.$store.state.orders.Loading;
    },
    role() {
      return localStorage.getItem("role");
    },
    products() {
      return this.$store.state.products.products_sku;
    },
    coupons() {
      return this.$store.state.coupons.coupons;
    },
    deliveries() {
      return this.$store.state.reps.reps;
    },
    validateForm() {
      return !this.errors.any();
    },
  },
  methods: {
    ...mapActions("orders", ["updateOrder", "fetchOrder"]),
    ...mapActions("products", ["productsSKU", "fetchQuantities"]),
    ...mapActions("users", ["fetchEndUsers"]),
    ...mapActions("coupons", ["fetchCoupons"]),
    ...mapActions("reps", ["fetchReps"]),
    resetInputs() {
      this.product = "";
      this.quantity = "";
      this.color_option = {}; 
      this.size_option = {id: "", quantity: '', size: ""};
    },
    getOrderData() {
      // this.$vs.loading();
      this.fetchOrder(this.$route.params.id).then((response) => {
        this.order = response.data.data;

        this.isEditable = this.checkEditable();

        this.order.items = this.order.items.map((item) => {
          return {
            sku: item.product.sku,
            id: item.product.id,
            title: item.product.title,
            price: item.product.price,
            new_price: item.product.new_price
              ? item.product.new_price
              : item.product.discount_price,
            color: item.quantity_details.color.name,
            size: item.quantity_details.size,
            quantity_id: item.quantity_id,
            quantity: item.quantity,
            currency: item.product.currency,
            total_amount: item.total_amount,
            buy_price: item.quantity ? item.total_amount / item.quantity : 0,
          };
        });
        // this.$vs.loading.close();
      });
    },
    getProductData(product) {
      this.fetchQuantities(product.id).then((response) => {
        this.quantities = response.data.data.quantities;
      });
    },
    clearFields() {
      (this.color_option = {}), (this.size_option = {id: "", quantity: '', size: ""});
    },
    deleteItem(index, id) {
      this.deletedItems.push(id);
      this.order.items.splice(index, 1);
    },
    showItem(id) {
      this.$router.push({ path: `/e-commerce/products/edit/${id}` });
    },
    addProduct() {
      let new_product = {
        id: this.product.id,
        sku: this.product.sku,
        title: this.product.title,
        currency: this.product.currency,
        price: this.product.price,
        total_quantity: this.size_option.quantity,
        new_price: this.product.new_price
          ? this.product.new_price
          : this.product.discount_price,
        color: this.color_option.name,
        size: this.size_option.size,
        quantity_id: this.size_option.id,
        quantity: this.quantity,
      };
      let duplicated = this.order.items.find(
        (item) => item.id == new_product.id
      );
      if (duplicated) {
        this.$vs.notify({
          time: 10000,
          title: "خطأ !",
          text: "المنتج موجود مسبقا",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        return false;
      }
      this.order.items.push(new_product);
      this.product = {};
      this.color_option = {};
      this.size_option = {};
      this.quantity = '';
    },

    submit() {
      this.$validator.validateAll().then((result) => {

        if (this.order.items.length < 1) {
          this.$vs.notify({
            time: 10000,
            title: "خطأ !",
            text: "يرجى إضافة منتج علي الأقل",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
        if (result == false) {
          this.$vs.notify({
            time: 10000,
            title: "خطأ !",
            text: "يرجى ملئ كافة الحقول",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        } else {

          let id = this.$route.params.id;
          let bodyFormData = new FormData();
          bodyFormData.set("user_id", this.order.user_id);
          bodyFormData.set("order_status_id", this.order.order_status_id);

          if (this.order.delivery_id) {
            bodyFormData.set("delivery_id", this.order.delivery_id);
            bodyFormData.set(
              "delivery_date",
              this.formatDate(this.order.delivery_date)
            );
            bodyFormData.set("delivery_price", this.order.delivery_price);
          }


     if(this.order.invoice.coupon){
 bodyFormData.set("coupon_code", this.order.invoice.coupon.code);
     }
         
          bodyFormData.set(
            "payment_method_id",
            this.order.invoice.payment_method_id
          );

          this.deletedItems.map((item) => {
            bodyFormData.set("products[" + item + "]", 0);
          });

          for (var i = 0; i < this.order.items.length; i++) {
            bodyFormData.set(
              "products[" + this.order.items[i].id + "]",
              this.order.items[i].id
            );
            bodyFormData.set(
              "products[" + this.order.items[i].id + "][quantity_id]",
              this.order.items[i].quantity_id
            );
            bodyFormData.set(
              "products[" + this.order.items[i].id + "][quantity]",
              this.order.items[i].quantity
            );
          }

          this.updateOrder({ id, bodyFormData })
            .then((response) => {
              this.successDialog(response.data.message);

              let page = this.$route.query.page;
              let tab = this.$route.query.tab;

              this.$router.push({
                name: "orders",
                query: { page: page, tab:tab },
              });
            })
            .catch((error) => {
              this.errorDialog(error.response.data.message);
            });
        }
      });

    },
    checkEditable() {
      if (this.order.order_status_id == 6) {
        return true;
      }
    },
  },
  created() {
    this.getOrderData();
    this.productsSKU();
    this.fetchCoupons();
    this.fetchReps();
  },
  mounted(){

  }
};
</script>

<style>
.vs-divider--text {
  font-size: 18.48px;
}
.vx-col {
  margin-bottom: 10px;
}
</style>
